import React from "react";
import LessonLayout from "~/layouts/lesson";
import ContentWrapper from "~/components/content-wrapper";
import H1 from "~/components/Typography/H1";
import UnorderedList from "~/components/List/Unordered";
import Button from "~/components/button";

const Page = () => {
  return (
    <LessonLayout
      current_step={-1}
      lesson={"Lesson 4"}
      color={"ms"}
      total_count={15}
      module_title={undefined}
    >
      <ContentWrapper>
        <H1 underline>Lesson at a Glance</H1>

        <h2>Lesson Overview</h2>

        <p>Students analyze ads for different health products to learn about the risks and benefits associated with medications or treatments. They consider how and why people should be informed about such risks and benefits. Students also read an article about a real-life case of a medical procedure having an unexpected outcome.</p>

        <h3>Major Concepts</h3>

        <UnorderedList>
          <li>Medications, treatments, and interventions usually have both benefits and risks.</li>
          <li>A risk associated with using a health product or treatment may be an undesired effect on health. Other types of risks may be indirectly related to health (e.g., a loss of time or money associated with using a product or treatment).</li>
          <li>The regulations for developing and marketing prescription drugs are different from those for over-the-counter medications and dietary supplements.</li>
        </UnorderedList>

        <h3>Objectives</h3>

        <p>Upon completion of this lesson, students will be able to</p>

        <UnorderedList>
          <li>define and give examples of potential risks and benefits of a medication or treatment;</li>
          <li>describe differences in the regulations that companies must follow for advertising prescription medications, over-the-counter medications, and dietary supplements; and</li>
          <li>write statements about risks and benefits for their rubrics.</li>
        </UnorderedList>

        <h2>In Advance</h2>

        <p><span className="italic font-semibold">Estimated time for the lesson: </span> 2 45-minute class periods</p>

        <p><span className="italic font-semibold">Screens to project: </span>Incorrect Product Claim Ad

 (optional)</p>

        <p className="italic font-semibold">Materials</p>
        <UnorderedList>
          <li>Handout: Stelara® Commercial Transcript (optional; 1 copy per student; see <em>Preparation</em>)</li>
          <li>2 different colored pens or pencils (optional; 1 set per student)</li>
          <li>Students will continue to use the rubrics that they started in previous lessons.</li>
        </UnorderedList>

        <p className="italic font-semibold">Preparation</p>
        <UnorderedList>
          <li>Read through and review information in this lesson.</li>
          <li>Print 1 copy of the Stelara® Commercial Transcript per student if you feel that your students would find it easier to work from a hard copy (Step 5). If you choose this option, students can highlight benefits in 1 color pen or pencil and risks in a different color.</li>
          <li>If appropriate, bookmark the internet sites that students will use during the lesson.</li>
        </UnorderedList>

        <Button
          action="secondary"
          className="mt-5"
          color="purple"
          href="/files/hs/lesson_4/stelara_commercial_transcript.pdf"
          icon="download"
          title="Stelara Commercial Transcript"
        >
          Stelara Commercial Transcript
        </Button>
      </ContentWrapper>
    </LessonLayout>
  )
}

export default Page;

